import React from "react";
import { useAtom, userRegistrationsAtom, userRolesAtom } from "~/atoms";

export function useUserRoles() {
  const [userRoles, setUserRoles] = useAtom(userRolesAtom);
  const [userRegistrations, setUserRegistrations] = useAtom(
    userRegistrationsAtom,
  );
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setLoading(true);
    fetch("/api/user-roles-registrations")
      .then((res) => res.json())
      .then((res) => {
        setUserRoles(res.roles);
        setUserRegistrations(res.streamerRegistrations);
      })
      .finally(() => setLoading(false));
  }, [setUserRoles, setUserRegistrations]);

  return {
    userRoles,
    loading,
    userRegistrations,
  };
}
