import { Tooltip, TooltipAnchor, TooltipProvider } from "@ariakit/react";
import { Link } from "@remix-run/react";
import { formatDistanceToNowStrict, parseISO } from "date-fns";
import { useAtom } from "jotai";
import { Check, Loader2 } from "lucide-react";
import React from "react";
import { activitiesAtom } from "~/atoms";
import * as toast from "~/components/toast";
import { useActivities } from "~/hooks/use-activities";
import type { Activity } from "~/types";
import { cn } from "~/util/css";
import { parseData } from "~/util/parse-data";

const avatarCache: {
  [userId: string]: { avatarUrl: string; username: string };
} = {};

function Avatar(props: {
  userId?: string;
  setUsername?: (username: string) => void;
}) {
  const [userAvatarUrl, setUserAvatarUrl] = React.useState("");

  React.useEffect(() => {
    if (!props.userId) return;
    // Checking if the user data is already cached
    if (avatarCache[props.userId]) {
      const cachedUser = avatarCache[props.userId];
      setUserAvatarUrl(cachedUser.avatarUrl);
      if (props.setUsername) {
        props.setUsername(cachedUser.username);
      }
    } else {
      // Fetching the user data if not cached
      fetch(`/api/users/${props.userId}`)
        .then((res) => res.json())
        .then((res) => {
          setUserAvatarUrl(res.user.avatar_url);
          if (props.setUsername) {
            props.setUsername(res.user.username);
          }
          avatarCache[props.userId ?? 0] = {
            avatarUrl: res.user.avatar_url,
            username: res.user.username,
          };
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });
    }
  }, [props.userId, props.setUsername]);

  return (
    <div
      className="w-10 h-10 border-2 border-black rounded-full bg-cover bg-center"
      style={{
        backgroundImage: `url(${userAvatarUrl || "/default-avatar.svg"})`,
      }}
    />
  );
}

function ReceivedDripNotification(props: { activity: Activity }) {
  const data = parseData(props.activity.data);
  return (
    <Item>
      <Avatar userId={props.activity.source_user_id} />
      <div
        className={cn("flex-1 flex flex-col gap-1", {
          "opacity-50": props.activity.read,
        })}
      >
        <div>
          You received <strong>{data?.points} </strong>DRIP.
        </div>
        <div>
          {formatDistanceToNowStrict(parseISO(props.activity.created_at), {
            addSuffix: true,
          })}
        </div>
      </div>
      <div className="flex items-center justify-center">
        {!props.activity.read && (
          <div className="hidden group-hover:block">
            <ReadActivityButton activityId={props.activity.id} />
          </div>
        )}
      </div>
    </Item>
  );
}

function PostSlappedNotification(props: { activity: Activity }) {
  const data = parseData(props.activity.data);
  return (
    <Item>
      <Avatar userId={props.activity.source_user_id} />
      <div className="flex-1 flex flex-col gap-1">
        <div>
          You Earned <strong>{data?.points} </strong>DRIP for getting stickers
          slapped on your posts.
        </div>
        <div>
          {formatDistanceToNowStrict(parseISO(props.activity.updated_at), {
            addSuffix: true,
          })}
        </div>
      </div>
      <div className="hidden items-center justify-center group-hover:flex">
        {!props.activity.read && (
          <ReadActivityButton activityId={props.activity.id} />
        )}
      </div>
    </Item>
  );
}

function SlappedStickerNotification(props: { activity: Activity }) {
  const data = parseData(props.activity.data);
  return (
    <Item>
      <Avatar userId={props.activity.source_user_id} />
      <div className="flex-1 flex flex-col gap-1">
        <div>
          You Earned <strong>{data?.points} </strong>DRIP for slapping stickers.
        </div>
        <div>
          {formatDistanceToNowStrict(parseISO(props.activity.updated_at), {
            addSuffix: true,
          })}
        </div>
      </div>
      <div className="hidden items-center justify-center group-hover:flex">
        {!props.activity.read && (
          <ReadActivityButton activityId={props.activity.id} />
        )}
      </div>
    </Item>
  );
}

function SignupNotification(props: { activity: Activity }) {
  return (
    <Item>
      <Avatar userId={props.activity.source_user_id} />
      <div
        className={cn("flex-1 flex flex-col gap-1", {
          "opacity-50": props.activity.read,
        })}
      >
        <div>You signed up.</div>
        <div>
          {formatDistanceToNowStrict(parseISO(props.activity.created_at), {
            addSuffix: true,
          })}
        </div>
      </div>
      <div className="hidden items-center justify-center group-hover:flex">
        {!props.activity.read && (
          <ReadActivityButton activityId={props.activity.id} />
        )}
      </div>
    </Item>
  );
}

function ReferrerNotification(props: { activity: Activity }) {
  const data = parseData(props.activity.data);
  const [username, setUsername] = React.useState("");
  return (
    <Item>
      <Avatar
        userId={data.referred_id ?? data.referred_to_id}
        setUsername={(username) => setUsername(username)}
      />
      <div
        className={cn("flex-1 flex flex-col gap-1", {
          "opacity-50": props.activity.read,
        })}
      >
        <div>
          <Link to={`/${username}`}>
            <strong>{username}</strong>
          </Link>{" "}
          signed up using your referral code.
        </div>
        <div>
          {formatDistanceToNowStrict(parseISO(props.activity.created_at), {
            addSuffix: true,
          })}
        </div>
      </div>
      <div className="hidden items-center justify-center group-hover:flex">
        {!props.activity.read && (
          <ReadActivityButton activityId={props.activity.id} />
        )}
      </div>
    </Item>
  );
}

function Item(props: { children: React.ReactNode }) {
  return (
    <div className="flex gap-3 first:pt-0 pt-2 group">{props.children}</div>
  );
}

interface ActivityPanelProps {
  hasReadAllNotifications: boolean;
}

export function ActivityPanel({ hasReadAllNotifications }: ActivityPanelProps) {
  const { loading, activities } = useActivities();
  const [_, setActivities] = useAtom(activitiesAtom);

  React.useEffect(() => {
    if (hasReadAllNotifications) {
      setActivities((activities) =>
        activities.map((activity) => ({ ...activity, read: true })),
      );
    }
  }, [hasReadAllNotifications, setActivities]);

  if (loading) {
    return (
      <div className="py-10 flex items-center justify-center gap-2">
        <Loader2 size={24} className="animate-spin" />
        <p className="text-base">Loading activities...</p>
      </div>
    );
  }

  if (activities.length === 0) {
    return (
      <div className="py-10 flex items-center justify-center">
        <p className="text-base">No activities yet.</p>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 gap-2 px-4 divide-y max-w-2xl mx-auto">
      {activities
        .map((activity) => {
          switch (activity.activity_type) {
            case "new_signup":
              return (
                <SignupNotification key={activity.id} activity={activity} />
              );
            case "referral_signup":
              return (
                <ReferrerNotification key={activity.id} activity={activity} />
              );
            case "post_slapped":
              return (
                <PostSlappedNotification
                  key={activity.id}
                  activity={activity}
                />
              );
            case "slapped_sticker":
              return (
                <SlappedStickerNotification
                  key={activity.id}
                  activity={activity}
                />
              );
            case "received_drip":
              return (
                <ReceivedDripNotification
                  key={activity.id}
                  activity={activity}
                />
              );
            case "received_comment":
              return (
                <PostedCommentNotification
                  key={activity.id}
                  activity={activity}
                />
              );
            case "sticker_sold":
              return (
                <StickerSoldNotification
                  key={activity.id}
                  activity={activity}
                />
              );
            case "quest_submission_approved":
              return (
                <QuestSubmissionApproved
                  key={activity.id}
                  activity={activity}
                />
              );
            case "quest_submission_rejected":
              return (
                <QuestSubmissionRejected
                  key={activity.id}
                  activity={activity}
                />
              );
            case "community_membership_approved":
              return (
                <CommunityMembershipApproved
                  key={activity.id}
                  activity={activity}
                />
              );
            case "community_membership_rejected":
              return (
                <CommunityMembershipRejected
                  key={activity.id}
                  activity={activity}
                />
              );
            case "community_membership_revoked":
              return (
                <CommunityMembershipRevoked
                  key={activity.id}
                  activity={activity}
                />
              );
            case "claimed_sticker_pack_reward":
              return (
                <ClaimedStickerPackRewardNotification
                  key={activity.id}
                  activity={activity}
                />
              );
            case "mentioned_user":
            case "comment_mention":
              return (
                <MentionedUserNotification
                  key={activity.id}
                  activity={activity}
                />
              );
            default:
              return null;
          }
        })
        .filter(Boolean)}
    </div>
  );
}

interface ReadActivityButtonProps {
  activityId: number;
}

function ReadActivityButton(props: ReadActivityButtonProps) {
  const [loading, setLoading] = React.useState(false);
  const [_, setActivities] = useAtom(activitiesAtom);

  const handleClick = async () => {
    setLoading(true);
    const response = await fetch(
      `/api/activities/${props.activityId}/mark-as-read`,
      {
        method: "POST",
      },
    );
    if (!response.ok) {
      toast.fail({
        text: "There was an issue marking activity as read right now. Please try again later.",
        id: "error",
      });
    }
    setActivities((activities) =>
      activities.map((activity) =>
        activity.id === props.activityId
          ? { ...activity, read: true }
          : activity,
      ),
    );
    setLoading(false);
  };

  return (
    <TooltipProvider placement="top-end">
      <TooltipAnchor
        className="border-2 border-pink-300 rounded p-[1px] text-pink-300"
        render={
          <button onClick={handleClick} disabled={loading} type="button" />
        }
      >
        <Check size={15} />
      </TooltipAnchor>
      <Tooltip className="z-[60] shadow-sm bg-black px-2 py-1 rounded text-white text-sm">
        Mark as read
      </Tooltip>
    </TooltipProvider>
  );
}

function PostedCommentNotification(props: { activity: Activity }) {
  const data = parseData(props.activity.data);
  return (
    <Item>
      <Avatar userId={data.commenterUserId} />
      <div
        className={cn("flex-1 flex flex-col gap-1", {
          "opacity-50": props.activity.read,
        })}
      >
        <div>
          <a href={`/${data.author}`} className="font-bold hover:underline">
            {data.author}
          </a>{" "}
          commented on your{" "}
          <a
            href={`/post/${data.postId}`}
            className="font-bold hover:underline"
          >
            post.
          </a>
        </div>
        <div>
          {formatDistanceToNowStrict(parseISO(props.activity.created_at), {
            addSuffix: true,
          })}
        </div>
      </div>
      <div className="hidden items-center justify-center group-hover:flex">
        {!props.activity.read && (
          <ReadActivityButton activityId={props.activity.id} />
        )}
      </div>
    </Item>
  );
}

function StickerSoldNotification(props: { activity: Activity }) {
  const data = parseData(props.activity.data);
  return (
    <Item>
      <Avatar userId={data.buyerUserId} />
      <div
        className={cn("flex-1 flex flex-col gap-1", {
          "opacity-50": props.activity.read,
        })}
      >
        <div>
          <a
            href={`/${data.buyerUsername}`}
            className="font-bold hover:underline"
          >
            {data.buyerUsername}
          </a>{" "}
          bought your sticker{" "}
          <a
            href={`/sticker/${data.stickerId}`}
            className="font-bold hover:underline"
          >
            {data.stickerId}
          </a>{" "}
          for {data.priceAmount} {data.priceCurrency}
        </div>
        <div>
          {formatDistanceToNowStrict(parseISO(props.activity.created_at), {
            addSuffix: true,
          })}
        </div>
      </div>
      <div className="hidden items-center justify-center group-hover:flex">
        {!props.activity.read && (
          <ReadActivityButton activityId={props.activity.id} />
        )}
      </div>
    </Item>
  );
}

function QuestSubmissionApproved(props: { activity: Activity }) {
  const data = parseData(props.activity.data);
  return (
    <Item>
      <Avatar userId={props.activity.source_user_id} />
      <div
        className={cn("flex-1 flex flex-col gap-1", {
          "opacity-50": props.activity.read,
        })}
      >
        <p>
          Claim your DRIP for completing Quest:{" "}
          <strong>
            <a className="hover:underline" href={`/quests/${data.questSlug}`}>
              {data.questName}
            </a>
          </strong>{" "}
        </p>
        <p>
          Click{" "}
          <a
            href={`/spins/${data.spinId}`}
            className="font-medium hover:underline text-blue-500"
          >
            here
          </a>{" "}
          to claim your DRIP.
        </p>
        <div>
          {formatDistanceToNowStrict(parseISO(props.activity.created_at), {
            addSuffix: true,
          })}
        </div>
      </div>
      <div className="hidden items-center justify-center group-hover:flex">
        {!props.activity.read && (
          <ReadActivityButton activityId={props.activity.id} />
        )}
      </div>
    </Item>
  );
}

function QuestSubmissionRejected(props: { activity: Activity }) {
  const data = parseData(props.activity.data);
  return (
    <Item>
      <Avatar userId={props.activity.source_user_id} />
      <div
        className={cn("flex-1 flex flex-col gap-1", {
          "opacity-50": props.activity.read,
        })}
      >
        <p>
          Your{" "}
          <strong>
            <a className="hover:underline" href={`/quests/${data.questSlug}`}>
              {data.questName}
            </a>
          </strong>{" "}
          quest submission has been rejected
        </p>
        <p>
          Reason: <strong>{data.rejectReason}</strong>
        </p>
        <div>
          {formatDistanceToNowStrict(parseISO(props.activity.created_at), {
            addSuffix: true,
          })}
        </div>
      </div>
      <div className="hidden items-center justify-center group-hover:flex">
        {!props.activity.read && (
          <ReadActivityButton activityId={props.activity.id} />
        )}
      </div>
    </Item>
  );
}

function CommunityMembershipApproved(props: { activity: Activity }) {
  const data = parseData(props.activity.data);
  return (
    <Item>
      <Avatar userId={props.activity.source_user_id} />
      <div
        className={cn("flex-1 flex flex-col gap-1", {
          "opacity-50": props.activity.read,
        })}
      >
        <p>
          You have been approved to join{" "}
          <strong>
            <a
              className="hover:underline"
              href={`/communities/${data.communitySlug}`}
            >
              {data.communityName}
            </a>
          </strong>
          .
        </p>
        <div>
          {formatDistanceToNowStrict(parseISO(props.activity.created_at), {
            addSuffix: true,
          })}
        </div>
      </div>
      <div className="hidden items-center justify-center group-hover:flex">
        {!props.activity.read && (
          <ReadActivityButton activityId={props.activity.id} />
        )}
      </div>
    </Item>
  );
}

function CommunityMembershipRejected(props: { activity: Activity }) {
  const data = parseData(props.activity.data);
  return (
    <Item>
      <Avatar userId={props.activity.source_user_id} />
      <div
        className={cn("flex-1 flex flex-col gap-1", {
          "opacity-50": props.activity.read,
        })}
      >
        <p>
          Your application to join{" "}
          <strong>
            <a
              className="hover:underline"
              href={`/communities/${data.communitySlug}`}
            >
              {data.communityName}
            </a>
          </strong>{" "}
          has been disapproved.
        </p>
        <div>
          {formatDistanceToNowStrict(parseISO(props.activity.created_at), {
            addSuffix: true,
          })}
        </div>
      </div>
      <div className="hidden items-center justify-center group-hover:flex">
        {!props.activity.read && (
          <ReadActivityButton activityId={props.activity.id} />
        )}
      </div>
    </Item>
  );
}

function CommunityMembershipRevoked(props: { activity: Activity }) {
  const data = parseData(props.activity.data);
  return (
    <Item>
      <Avatar userId={props.activity.source_user_id} />
      <div
        className={cn("flex-1 flex flex-col gap-1", {
          "opacity-50": props.activity.read,
        })}
      >
        <p>
          Your membership to{" "}
          <strong>
            <a
              className="hover:underline"
              href={`/communities/${data.communitySlug}`}
            >
              {data.communityName}
            </a>
          </strong>{" "}
          has been revoked.
        </p>
        <div>
          {formatDistanceToNowStrict(parseISO(props.activity.created_at), {
            addSuffix: true,
          })}
        </div>
      </div>
      <div className="hidden items-center justify-center group-hover:flex">
        {!props.activity.read && (
          <ReadActivityButton activityId={props.activity.id} />
        )}
      </div>
    </Item>
  );
}

function ClaimedStickerPackRewardNotification(props: { activity: Activity }) {
  const data = parseData(props.activity.data);
  return (
    <Item>
      <Avatar userId={props.activity.source_user_id} />
      <div
        className={cn("flex-1 flex flex-col gap-1", {
          "opacity-50": props.activity.read,
        })}
      >
        <p>
          You received your{" "}
          <strong>
            <a
              className="hover:underline"
              href={`/sticker-pack/${data.stickerPackId}`}
            >
              sticker pack
            </a>
          </strong>{" "}
          reward.
        </p>
        <div>
          {formatDistanceToNowStrict(parseISO(props.activity.created_at), {
            addSuffix: true,
          })}
        </div>
      </div>
      <div className="hidden items-center justify-center group-hover:flex">
        {!props.activity.read && (
          <ReadActivityButton activityId={props.activity.id} />
        )}
      </div>
    </Item>
  );
}

function MentionedUserNotification({ activity }: { activity: Activity }) {
  const data = parseData(activity.data);

  const isCommentMention = data.activityType === "comment_mention";
  const mentionType = isCommentMention ? "comment" : "post";

  return (
    <Item>
      <Avatar userId={data.mentionerId || activity.source_user_id} />
      <div
        className={cn("flex-1 flex flex-col gap-1", {
          "opacity-50": activity.read,
        })}
      >
        <div>
          <a href={`/${data.author}`} className="font-bold hover:underline">
            {data.author}
          </a>{" "}
          mentioned you in a{" "}
          <a
            href={`/post/${data.postId}`}
            className="font-bold hover:underline"
          >
            {mentionType}
          </a>
          .
        </div>
        <div>
          {formatDistanceToNowStrict(parseISO(activity.created_at), {
            addSuffix: true,
          })}
        </div>
      </div>
      <div className="hidden items-center justify-center group-hover:flex">
        {!activity.read && <ReadActivityButton activityId={activity.id} />}
      </div>
    </Item>
  );
}
