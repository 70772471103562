import {
  Popover,
  PopoverDisclosure,
  PopoverProvider,
  usePopoverStore,
} from "@ariakit/react";
import { Link, useFetcher, useLocation } from "@remix-run/react";
import { useQuery } from "@tanstack/react-query";
import { AnimatePresence, motion } from "framer-motion";
import { ArrowLeft, ArrowRight, Calendar, Menu } from "lucide-react";
import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { ClientOnly } from "remix-utils/client-only";
import { useDisconnect } from "wagmi";
import { Button } from "~/components/button";
import * as config from "~/config";
import { AdminshipRecordType } from "~/enums";
import { useActivities } from "~/hooks/use-activities";
import { useCurrentUser } from "~/hooks/use-current-user";
import { useUserRoles } from "~/hooks/use-user-roles";
import { useUserStats } from "~/hooks/use-user-stats";
import { cn } from "~/util/css";
import { formatNumber } from "../util/number";
import { ActivityPanel } from "./activity-panel";
import InviteModal from "./invite-modal";
import { Sidenav } from "./sidenav";
import { Wallet } from "./wallet";

interface TopBarProps {
  className?: string;
  showLogo?: boolean;
}

export function TopBar(props: TopBarProps) {
  const [currentUser] = useCurrentUser();
  const [isOpenMobileSidebar, setIsOpenMobileSidebar] = React.useState(false);
  const { pathname, key: routeKey } = useLocation();
  const { userStats } = useUserStats(currentUser?.username);
  const [openInviteModal, setOpenInviteModal] = React.useState<boolean>(false);
  const { userRoles, userRegistrations } = useUserRoles();

  React.useEffect(() => {
    // on route changes close sidebar
    if (routeKey) {
      setIsOpenMobileSidebar(false);
    }
  }, [routeKey]);

  return (
    <>
      <div className={cn("w-full p-6 text-sm h-[80px]", props.className)}>
        <div className="flex items-center h-full w-full gap-4">
          <div className="flex items-center gap-2">
            <button
              onClick={() => setIsOpenMobileSidebar(true)}
              className="lg:hidden"
              type="button"
            >
              <Menu size={24} />
            </button>
            {props.showLogo && (
              <Link to="/" className="hidden lg:block">
                <img
                  src="/soulbound-beta.webp"
                  className="w-[120px]"
                  alt="Soulbound"
                />
              </Link>
            )}
            <Link to="/" className="lg:hidden">
              <img
                src="/soulbound-beta.webp"
                className="w-[90px]"
                alt="Soulbound"
              />
            </Link>
            {currentUser &&
              config.features?.enableStickerCreation &&
              !pathname.startsWith("/sticker-pack/new") && (
                <Button
                  className="py-[6px] border-pink-300 hidden text-sm font-medium lg:flex lg:text-xs xl:text-sm"
                  asChild
                >
                  <Link to="/sticker-pack/new">
                    <img
                      src="/icons/lootbox-pink.svg"
                      alt="lootbox"
                      className="w-6 h-6"
                    />
                    Create Stickers
                  </Link>
                </Button>
              )}
            {currentUser && (
              <>
                <Button
                  className="py-[6px] border-blue-300 hidden text-sm font-medium lg:flex lg:text-xs xl:text-sm"
                  asChild
                  variant="secondary"
                >
                  <button
                    onClick={() => setOpenInviteModal(true)}
                    type="button"
                  >
                    <img
                      src="/icons/shop.svg"
                      alt="invite"
                      className="w-6 h-6"
                    />
                    Invite a friend
                  </button>
                </Button>
                {openInviteModal && (
                  <InviteModal
                    open={openInviteModal}
                    onClose={() => setOpenInviteModal(false)}
                  />
                )}
              </>
            )}
          </div>
          {!pathname.startsWith("/quests/onboard") && (
            <div className="flex items-center justify-end ml-auto xl:gap-6 gap-3">
              {currentUser ? (
                <>
                  {config.features?.enableStreamerApplications &&
                    !userRoles.includes("streamer") &&
                    Array.isArray(userRegistrations) &&
                    (userRegistrations.length === 0 ||
                      !userRegistrations.some(
                        (registration) => registration.status === "pending",
                      )) && (
                      <Link
                        className="border-[#FFA9DE] bg-[#FFEDFC] items-center justify-center py-[6px] px-4 lg:text-xs xl:text-sm border-2 rounded-full hover:bg-pink-200 font-medium gap-1 hidden sm:flex"
                        to="/streamer"
                      >
                        <img
                          className="h-6"
                          src="/icons/launch.svg"
                          alt="Apply as a Streamer"
                        />
                        Apply as a Streamer
                      </Link>
                    )}
                  <div className="hidden sm:flex items-center gap-2">
                    <p className="text-white">SPINS</p>
                    <Link
                      to="/spins"
                      className="bg-[#99E6FF] px-[6px] rounded-full text-xs font-bold"
                    >
                      {userStats.availableSpins}
                    </Link>
                  </div>
                  <div className="hidden sm:flex items-center gap-2">
                    <p className="text-white">DRIP</p>
                    <p className="bg-[#99E6FF] px-[6px] rounded-full text-xs font-bold">
                      {formatNumber(userStats.points)}
                    </p>
                  </div>
                  <ActivityBell />
                  <UpcomingCalendarEventsPopover />
                  <ProfileMenu />
                </>
              ) : (
                <>
                  <UpcomingCalendarEventsPopover />
                  <Button asChild>
                    <Link to="/login">
                      <span>Sign In</span>
                    </Link>
                  </Button>
                </>
              )}
            </div>
          )}
        </div>
      </div>

      {/* sliding mobile sidebar*/}
      <Sliding
        isOpen={isOpenMobileSidebar}
        onClose={() => setIsOpenMobileSidebar(false)}
        position="left"
        className="w-[320px]"
      >
        <div className="flex w-full">
          <div className="py-7 px-4 flex-1">
            <Sidenav />
          </div>
          <SidebarArrow
            position="left"
            onClick={() => setIsOpenMobileSidebar(false)}
          />
        </div>
      </Sliding>
    </>
  );
}

interface SlidingProps {
  isOpen: boolean;
  children: React.ReactNode;
  position: "left" | "right";
  className?: string;
  onClose: () => void;
}

function Sliding(props: SlidingProps) {
  const translateX = props.position === "right" ? "100%" : "-100%";
  return (
    <AnimatePresence>
      {props.isOpen && (
        <div className="fixed z-50 top-0 left-0 w-full h-full">
          <div
            className="absolute top-0 left-0 w-full h-full"
            onClick={props.onClose}
          />
          <motion.div
            initial={{ translateX }}
            animate={{
              translateX: 0,
              transition: {
                duration: 0.2,
              },
            }}
            exit={{ translateX, transition: { duration: 0.1 } }}
            className={cn(
              "bg-[linear-gradient(180deg,#93B3FF_3.36%,#AB22FF_100%)] h-full w-72 z-10 absolute top-0 flex",
              {
                "right-0": props.position === "right",
                "left-0": props.position === "left",
              },
              props.className,
            )}
          >
            {props.children}
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
}

interface SidebarArrowProps {
  onClick: () => void;
  position: "left" | "right";
}

function SidebarArrow(props: SidebarArrowProps) {
  return (
    <div className="bg-pink-300 w-10 h-full flex justify-center">
      <button
        onClick={props.onClick}
        className="bg-black w-6 h-6 rounded-full flex items-center justify-center mt-[28px]"
      >
        {props.position === "right" ? (
          <ArrowRight className="text-pink-300 h-4 w-4" />
        ) : (
          <ArrowLeft className="text-pink-300 h-4 w-4" />
        )}
      </button>
    </div>
  );
}

function ActivityBell() {
  const popoverStore = usePopoverStore();
  const mounted = popoverStore.useState("mounted");
  const [hasReadAllNotifications, setHasReadAllNotifications] =
    React.useState(false);
  const { activities } = useActivities();
  const hasUnreadActivity = activities.some((activity) => !activity.read);

  return (
    <PopoverProvider store={popoverStore} placement="top-start">
      <PopoverDisclosure
        toggleOnClick={(e) => {
          e.preventDefault();
          popoverStore.toggle();
          return false;
        }}
        className="bg-[#BF78FF] hover:bg-primary py-1 px-[6px] rounded-md flex-none"
      >
        <span className="relative">
          <img src="/icons/bell.svg" className="w-[22px]" alt="icon" />
          {hasUnreadActivity && (
            <div className="w-[12px] h-[12px] bg-pink-300 rounded-full absolute bottom-[2px] right-[-2px] border-2 border-black" />
          )}
        </span>
      </PopoverDisclosure>
      <AnimatePresence>
        {mounted && (
          <Popover
            alwaysVisible
            render={
              <motion.div
                initial={{ y: -20, opacity: 0 }}
                animate={{
                  y: 0,
                  opacity: 1,
                }}
                exit={{
                  opacity: 0,
                  y: -20,
                }}
                className={cn(
                  "bg-[#E9ECFF] rounded-lg rounded-r-none overflow-hidden z-30  mt-2 mb-3 border border-white w-96 text-black origin-top",
                )}
                style={{
                  boxShadow:
                    "0px 80px 100px 0px rgba(0, 0, 0, 0.07), 0px 41.78px 33.42px 0px rgba(0, 0, 0, 0.05), 0px 22.34px 17.87px 0px rgba(0, 0, 0, 0.04), 0px 12.52px 10.02px 0px rgba(0, 0, 0, 0.04), 0px 6.65px 5.32px 0px rgba(0, 0, 0, 0.03), 0px 2.77px 2.21px 0px rgba(0, 0, 0, 0.02)",
                }}
              />
            }
          >
            <div className="flex flex-col gap-2 py-4 h-full">
              <div className="flex flex-row">
                <div className="flex-grow">
                  <p className="font-semibold px-4 text-lg">Activities</p>
                </div>
                <div className="flex-shrink pr-4 content-center">
                  <button
                    type="button"
                    onClick={async () => {
                      await fetch("/api/activities/mark-all-read", {
                        method: "POST",
                      });
                      setHasReadAllNotifications(true);
                    }}
                  >
                    Clear all
                  </button>
                </div>
              </div>
              <Scrollbars universal autoHeight autoHide autoHeightMax={400}>
                <ActivityPanel
                  hasReadAllNotifications={hasReadAllNotifications}
                />
              </Scrollbars>
            </div>
          </Popover>
        )}
      </AnimatePresence>
    </PopoverProvider>
  );
}

function UpcomingCalendarEventsPopover() {
  const popoverStore = usePopoverStore();
  const mounted = popoverStore.useState("mounted");

  const fetchUpcomingEvents = async () => {
    try {
      const response = await fetch(`${window.ENV.EDGE_URL}/calendar-events`);
      return await response.json();
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const {
    data: events = [],
    isLoading,
    error,
  } = useQuery({
    queryKey: ["calendar-events"],
    queryFn: fetchUpcomingEvents,
  });

  const upcomingEvents = events.filter(
    (event) => new Date(event.start_date) > new Date(),
  );

  return (
    <PopoverProvider store={popoverStore} placement="top-start">
      <PopoverDisclosure
        toggleOnClick={(e) => {
          e.preventDefault();
          popoverStore.toggle();
          return false;
        }}
        className="bg-[#BF78FF] hover:bg-primary py-1 px-[6px] rounded-md flex-none"
      >
        <span className="relative">
          <Calendar className="w-[22px]" />
          {upcomingEvents.length > 0 && (
            <div className="w-[12px] h-[12px] bg-pink-300 rounded-full absolute bottom-[2px] right-[-2px] border-2 border-black" />
          )}
        </span>
      </PopoverDisclosure>
      <AnimatePresence>
        {mounted && (
          <Popover
            alwaysVisible
            render={
              <motion.div
                initial={{ y: -20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ opacity: 0, y: -20 }}
                className={cn(
                  "bg-white rounded-lg shadow-lg overflow-hidden z-30 mt-2 mb-3 border w-96 text-black origin-top",
                )}
                style={{
                  boxShadow:
                    "0px 80px 100px 0px rgba(0, 0, 0, 0.07), 0px 41.78px 33.42px 0px rgba(0, 0, 0, 0.05), 0px 22.34px 17.87px 0px rgba(0, 0, 0, 0.04), 0px 12.52px 10.02px 0px rgba(0, 0, 0, 0.04), 0px 6.65px 5.32px 0px rgba(0, 0, 0, 0.03), 0px 2.77px 2.21px 0px rgba(0, 0, 0, 0.02)",
                }}
              />
            }
          >
            <div className="flex flex-col gap-2 py-4 h-full">
              <div className="flex flex-row">
                <div className="flex-grow">
                  <p className="font-semibold px-4 text-lg">
                    Ongoing and Upcoming Events
                  </p>
                </div>
              </div>
              <Scrollbars universal autoHeight autoHide autoHeightMax={400}>
                <div className="flex flex-col gap-4 px-4">
                  {isLoading && <p>Loading...</p>}
                  {error && <p>Error loading events</p>}
                  {upcomingEvents.length > 0 ? (
                    upcomingEvents.map((event) => (
                      <Link
                        key={event.id}
                        to={event.link}
                        className="block p-4 rounded-lg hover:bg-gray-100 transition-all"
                        target="_blank"
                      >
                        <div className="flex flex-row gap-4 items-center">
                          <div className="text-center">
                            <p className="text-xl font-bold text-gray-600">
                              {new Date(event.start_date).getDate()}
                            </p>
                            <p className="text-sm text-gray-500">
                              {new Date(event.start_date)
                                .toLocaleString("default", {
                                  month: "short",
                                })
                                .toUpperCase()}
                            </p>
                          </div>
                          <div className="flex flex-col justify-between">
                            <p className="font-bold text-base text-gray-700">
                              {event.event_name}
                            </p>
                            <p className="text-xs text-gray-500">
                              {new Date(event.start_date).toLocaleTimeString(
                                "en-GB",
                                {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  timeZone: "UTC",
                                  timeZoneName: "short",
                                },
                              )}
                            </p>
                            <p className="text-sm text-gray-500">
                              {event.description}
                            </p>
                          </div>
                        </div>
                      </Link>
                    ))
                  ) : (
                    <p className="text-center">
                      No ongoing and upcoming events.
                    </p>
                  )}
                </div>
              </Scrollbars>
            </div>
          </Popover>
        )}
      </AnimatePresence>
    </PopoverProvider>
  );
}

function ProfileMenu() {
  const popoverStore = usePopoverStore();
  const mounted = popoverStore.useState("mounted");
  const [currentUser] = useCurrentUser();
  const { key: routeKey } = useLocation();
  const fetcher = useFetcher();
  const { isSuccess, disconnect } = useDisconnect();
  React.useEffect(() => {
    if (isSuccess) {
      fetcher.submit(null, {
        action: "/logout",
        method: "delete",
      });
    }
  }, [isSuccess]);

  React.useEffect(() => {
    // on route changes close menu
    if (routeKey) {
      popoverStore.hide();
    }
  }, [routeKey]);

  return (
    <PopoverProvider store={popoverStore} placement="top-start">
      <PopoverDisclosure
        toggleOnClick={(e) => {
          e.preventDefault();
          popoverStore.toggle();
          return false;
        }}
      >
        <div
          className={cn(
            "w-[2.15rem] h-[2.15rem] rounded-full group bg-[#49FBB6] transition-all duration-200 hover:bg-gradient-to-r hover:from-teal-400  hover:via-indigo-500 hover:to-purple-500 items-center justify-center flex",
            { "bg-white": mounted },
          )}
        >
          <img
            src={currentUser?.avatarUrl || "/default-avatar.svg"}
            alt="avatar"
            className="w-8 h-8 rounded-full group-hover:scale-90 object-cover object-center transition-all duration-200"
          />
        </div>
      </PopoverDisclosure>
      <AnimatePresence>
        {mounted && (
          <Popover
            alwaysVisible
            render={
              <motion.div
                initial={{ y: -20, opacity: 0 }}
                animate={{
                  y: 0,
                  opacity: 1,
                }}
                exit={{
                  opacity: 0,
                  y: -20,
                }}
                className={cn(
                  "bg-[#E9ECFF] rounded-lg rounded-r-none overflow-hidden z-30  mt-2 mb-3 border border-white w-96 text-black max-h-[500px] origin-top p-4",
                )}
                style={{
                  boxShadow:
                    "0px 80px 100px 0px rgba(0, 0, 0, 0.07), 0px 41.78px 33.42px 0px rgba(0, 0, 0, 0.05), 0px 22.34px 17.87px 0px rgba(0, 0, 0, 0.04), 0px 12.52px 10.02px 0px rgba(0, 0, 0, 0.04), 0px 6.65px 5.32px 0px rgba(0, 0, 0, 0.03), 0px 2.77px 2.21px 0px rgba(0, 0, 0, 0.02)",
                }}
              />
            }
          >
            <div className="flex flex-col gap-4">
              <ClientOnly>{() => <Wallet />}</ClientOnly>

              <div className="flex flex-col gap-3 w-full">
                <Link
                  to={`/${currentUser?.username || "quests/onboard"}`}
                  className="px-4 py-2 font-medium rounded-full w-full hover:bg-[linear-gradient(84deg,#BCBEFF_0%,#D2DCFF_64.26%,#F9FBFF_124.78%)]"
                >
                  My profile
                </Link>

                <Link
                  to="/user/socials"
                  className="px-4 py-2 font-medium rounded-full w-full hover:bg-[linear-gradient(84deg,#BCBEFF_0%,#D2DCFF_64.26%,#F9FBFF_124.78%)]"
                >
                  Social Accounts
                </Link>

                <Link
                  to="/user/edit"
                  className="px-4 py-2 font-medium rounded-full w-full hover:bg-[linear-gradient(84deg,#BCBEFF_0%,#D2DCFF_64.26%,#F9FBFF_124.78%)]"
                >
                  Edit profile
                </Link>

                {currentUser?.adminships?.map((adminship) => (
                  <Link
                    key={adminship.id}
                    to={`/adminship/${adminship.id}`}
                    className="px-4 py-2 font-medium rounded-full w-full hover:bg-[linear-gradient(84deg,#BCBEFF_0%,#D2DCFF_64.26%,#F9FBFF_124.78%)]"
                  >
                    {adminship.recordType === AdminshipRecordType.COMMUNITIES
                      ? "Community"
                      : "Game"}{" "}
                    Admin
                  </Link>
                ))}

                <Link
                  to="/logout"
                  className="px-4 py-2 font-medium rounded-full w-full text-[#6637CE] hover:bg-[linear-gradient(84deg,#BCBEFF_0%,#D2DCFF_64.26%,#F9FBFF_124.78%)]"
                  onClick={(e) => {
                    e.preventDefault();
                    disconnect();
                  }}
                >
                  Disconnect
                </Link>
              </div>
            </div>
          </Popover>
        )}
      </AnimatePresence>
    </PopoverProvider>
  );
}
